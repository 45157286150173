import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScanNotificationService } from '../scan-notification.service';
import { ScannerComponent } from '../scanner/scanner.component';
import { MatDialog } from '@angular/material/dialog';
import { NgClass } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ZbarAdapter } from '@lobos/scanner-adapter-zbar';

@UntilDestroy()
@Component({
  selector: 'app-scanner-button',
  templateUrl: './scanner-button.component.html',
  styleUrls: ['./scanner-button.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerButtonComponent {
  @Input() classes: string = '';

  constructor(
    private scannerAdapter: ZbarAdapter,
    private dialog: MatDialog,
    private scanNotification: ScanNotificationService,
  ) {
  }

  public openScanner(): void {
    this.scanNotification.prepare();
    this.scannerAdapter
      .askForPermission()
      .pipe(
        filter((result: boolean) => result),
        untilDestroyed(this),
      )
      .subscribe(() => this.dialog.open(ScannerComponent, { data: undefined }));
  }
}
