import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private showOutput: boolean = environment.consoleLogging;

  public error(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.error(message, params);
  }

  public log(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.log(message, params);
  }

  public warn(message: string, params?: unknown): void {
    if (!this.showOutput) {
      return;
    }

    console.warn(message, params);
  }
}
