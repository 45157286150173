import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { IconButtonComponent } from '../../../../button/icon-button/icon-button.component';
import { QuantityInputComponent } from '../../../../quantity-input/quantity-input.component';
import { ArticlePriceComponent } from '../../../../article-price/article-price.component';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { GlobalStore } from '../../../../../store/global.store';
import { SuedoFavoriteItem } from '../../../../../interfaces/suedo-favorite-item.interface';
import { UrlPathPipe } from '../../../../../pipes/url-path.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[app-favorites-flyout-item]',
  templateUrl: './favorite-item.component.html',
  styleUrls: ['./favorite-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    RouterLink,
    ArticlePriceComponent,
    QuantityInputComponent,
    IconButtonComponent,
    NgClass,
    AsyncPipe,
    UrlPathPipe,
  ],
})
export class FavoriteItemComponent {
  @Input() favoriteItem!: SuedoFavoriteItem;

  priceIsZero: boolean = false;
  store = inject(GlobalStore);


  async deleteFavoriteItem(favoriteItem: SuedoFavoriteItem) {
    await this.store.deleteFavoriteItem(favoriteItem.gListID.toString(), favoriteItem.shtItemID.toString());
  }

  async onQuantityChange(favoriteItem: SuedoFavoriteItem, quantity: number) {
    await this.store.updateFavoriteItem(favoriteItem.gListID.toString(), favoriteItem.shtItemID.toString(), {
      ...favoriteItem,
      decQuantity: quantity,
      gListID: favoriteItem.gListID as string,
      shtItemID: favoriteItem.shtItemID as number,
    });
  }

  async onAddArticleToCartClick(favoriteItem: SuedoFavoriteItem) {
    if (favoriteItem.decQuantity > 0 && favoriteItem.oArticle) {
      await this.store.createCartItem({
        decQuantity: favoriteItem.decQuantity,
        sArticleID: favoriteItem.oArticle!.sArticleID as string,
        sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
        oArticle: favoriteItem.oArticle,
      });
    }
  }
}
