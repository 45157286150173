import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectQuery } from '@ngneat/query';
import { FavoriteItem, FavoriteList, PaginationResponseInterface } from '@lobos/common-v3';

@Injectable({ providedIn: 'root' })
export class FavoriteService {
  http = inject(HttpClient);
  query = injectQuery();

  getFavoriteListById(id: string) {
    return this.query({
      queryKey: ['favorite', id] as const,
      queryFn: () => {
        return this.http.get<FavoriteList>(`/api/favorite/${id}`);
      },
    });
  }

  getFavoriteItems(id: string) {
    return this.query({
      queryKey: ['favorite-items', id] as const,
      queryFn: () => {
        return this.http.get<PaginationResponseInterface<FavoriteItem>>(`/api/favorites/${id}/items`);
      },
    });
  }
}
