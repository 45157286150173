import { Component, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FeatureStatus } from './store/model/feature-status.enum';
import { GlobalStore } from './store/global.store';
import { LanguageHelperService } from './services/language/language-helper.service';

@UntilDestroy()
@Component({
  selector: 'app-language-root-redirect',
  template: '',
  standalone: true,
})
export class LanguageRootComponent {
  routerService = inject(Router);
  readonly store = inject(GlobalStore);
  languageHelperService = inject(LanguageHelperService);

  constructor() {
    effect(async () => {
      if (this.store.authStatus() === FeatureStatus.initialized) {
        await this.setLanguageAndRedirect(await this.languageHelperService.determineUserLanguage());
      }
    });
  }

  private async setLanguageAndRedirect(language: string): Promise<void> {
    await this.routerService.navigate([language]);
  }
}
