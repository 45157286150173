import { Component, inject, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { FavoriteList } from '@lobos/common-v3';
import { GlobalStore } from '../../store/global.store';
import { InputComponent } from '../form/input/input.component';
import { GetErrorPipe } from '../../pipes/get-error.pipe';
import { TextButtonComponent } from '../button/text-button/text-button.component';

export interface FavoriteListData {
  favoriteList: FavoriteList | null | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-add-edit-favorite-modal',
  templateUrl: './add-edit-favorite-modal.component.html',
  styleUrls: ['./add-edit-favorite-modal.component.scss'],
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule, NgIf, InputComponent, GetErrorPipe, TextButtonComponent],
})
export class AddEditFavoriteModalComponent implements OnInit {
  store = inject(GlobalStore);
  errorText: string | undefined;

  public form: UntypedFormGroup = this.fb.group({ sListname: ['', Validators.required] });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<AddEditFavoriteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FavoriteListData,
  ) {}

  ngOnInit() {
    if (this.data && this.data.favoriteList) {
      this.form.patchValue({ ...this.data.favoriteList });
    }

    this.form.valueChanges
      .pipe(
        tap(() => (this.errorText = undefined)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  save(favorite: FavoriteList | undefined | null): void {
    this.errorText = undefined;
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    if (favorite) {
      this.store.updateFavoriteList(favorite.gListID.toString(), this.form.value);
    } else {
      this.store.createFavoriteList(this.form.value);
    }
    this.dialogRef.close()
  }
}
