import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser, NgClass, NgFor, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TextButtonComponent } from '../../../button/text-button/text-button.component';
import { FavoriteItemComponent } from './favorite-item/favorite-item.component';
import { CustomSelectComponent } from '../../../form/custom-select/custom-select.component';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { GlobalStore } from '../../../../store/global.store';
import { LbsFavoriteItem } from '../../../../store/model/lbs-favorite-item.interface';
import { AddEditFavoriteModalComponent } from '../../../add-edit-favorite-modal/add-edit-favorite-modal.component';
import { UrlPathPipe } from '../../../../pipes/url-path.pipe';

@UntilDestroy()
@Component({
  selector: 'app-favorites-flyout',
  templateUrl: './favorites-flyout.component.html',
  styleUrls: ['./favorites-flyout.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    NgClass,
    RouterLink,
    CustomSelectComponent,
    NgFor,
    FavoriteItemComponent,
    TextButtonComponent,
    UrlPathPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritesFlyoutComponent implements OnInit {
  @Input() public classes: string = '';
  @Output() closeMenu: EventEmitter<void> = new EventEmitter();
  @Output() closeMobileMenu: EventEmitter<void> = new EventEmitter();
  public isLoading: boolean = false;
  public mobile: boolean | undefined;
  bulkAddLoading: boolean = false;
  store = inject(GlobalStore);

  favoriteSelectOptions = computed(() => {
    return this.store.allFavoriteItemLists().map((favoriteList) => ({
      value: favoriteList.listHeader.sListname,
      key: favoriteList.listHeader.gListID as string,
    }));
  });

  constructor(
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  setActiveFavoriteList(favoriteListId: string | number) {
    this.store.changeActiveFavoriteList(favoriteListId.toString());
  }

  addFavoriteList(): void {
    this.dialog.open(AddEditFavoriteModalComponent, { data: { favoriteList: null } });
  }

  checkIfFavoriteListIsEmpty() {
    if (this.store.allFavoriteItemLists().length > 0) {
      this.closeMobileMenu.emit();
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window.screen.width < 1280) {
      this.mobile = true;
    }
  }

  async bulkAddToCart(items: LbsFavoriteItem[] | undefined) {
    if (!items) {
      return;
    }

    try {
      this.bulkAddLoading = true;
      //await this.cartBulkAddService.addAllToCart(items);
    } catch (error) {
      console.error('Error occurred while adding items to the cart:', error);
    } finally {
      this.bulkAddLoading = false;
    }
  }

  addAllToCart() {
    // end convert stream
    // const end = new Subject<boolean>();
    // const end$ = of(undefined).pipe(
    //   tap(() => {
    //     end.next(true);
    //     end.complete();
    //   }),
    // );

    this.isLoading = true;
    // convert favorite items to cart items
    // this.activeFavoriteItems$
    //   .pipe(
    //     switchMap((favorites: FavoriteItem[]) =>
    //       merge([
    //         ...favorites
    //           .filter((f: FavoriteItem) => f.decQuantity > 0)
    //           .map((favoriteItem: FavoriteItem) =>
    //             this.cartService.createCartItem({
    //               decQuantity: favoriteItem.decQuantity,
    //               sArticleID: favoriteItem.oArticle!.sArticleID as string,
    //               sArticleName: favoriteItem.oArticle!.sName,
    //               sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
    //               oArticle: favoriteItem.oArticle!,
    //               emitHook: false,
    //             }),
    //           ),
    //         end$,
    //       ]),
    //     ),
    //     mergeMap(($req) => $req, 1),
    //     untilDestroyed(this),
    //     takeUntil(end),
    //     finalize(() => (this.isLoading = false)),
    //   )
    //   .subscribe();
  }
}
