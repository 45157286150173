import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { NavigationInterface } from '../header.component';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { ServiceNavigationComponent } from '../service-navigation/service-navigation.component';
import { GlobalStore } from '../../../store/global.store';
import { AlternateVersionService } from '../../../services/alternate-version/alternate-version.service';
import { LanguageService } from '../../../services/language/language.service';
import { UrlPathPipe } from '../../../pipes/url-path.pipe';
import { ToggleMenuDirective } from '../../../directives/toggle-menu.directive';
import { OrderByPipe } from '../../../pipes/order-by.pipe';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    ServiceNavigationComponent,
    SearchComponent,
    NgIf,
    NgFor,
    RouterLink,
    UrlPathPipe,
    ToggleMenuDirective,
    OrderByPipe,
  ],
})
export class MobileNavigationComponent {
  @Input() navigationMenu!: NavigationInterface[];
  @Output() closeMobileMenu: EventEmitter<Event> = new EventEmitter<Event>();
  store = inject(GlobalStore);
  transloco = inject(TranslocoService);
  alternateVersionService = inject(AlternateVersionService);
  languageService = inject(LanguageService);
  languages = this.languageService.getLanguages().result;

  changeLanguage(lang: string) {
    this.alternateVersionService.routeToVersion(lang);
  }
}
