import { Route } from '@angular/router';
import { LanguageRootComponent } from './language-root.component';
import { LanguageResolver } from './resolvers/language.resolver';
import { RootComponent } from './components/root/root.component';
import { authGuard } from './guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    component: LanguageRootComponent,
    children: [],
  },
  {
    path: '',
    loadChildren: () => import('./features/redirect/redirect.routes').then((m) => m.redirectRoutes),
  },
  {
    path: ':lang',
    resolve: [LanguageResolver],
    component: RootComponent,
    children: [
      {
        path: 'registration',
        loadChildren: () => import('./features/registration/registration.routes').then((m) => m.registrationRoutes),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./features/checkout/checkout.routes').then((m) => m.checkoutRoutes),
      },
      {
        path: 'profile',
        canActivate: [authGuard],
        loadChildren: () => import('./features/profile/profile.routes').then((m) => m.profileRoutes),
      },
      {
        path: 'downloads',
        loadComponent: () => import('./features/cms/download-page/downloads.component').then((m) => m.DownloadsComponent),
      },
      {
        path: 'comparison',
        loadComponent: () => import('./features/compare/compare-page/compare-page.component').then((m) => m.ComparePageComponent),
      },
      {
        path: 'sales-rep',
        loadChildren: () => import('./features/sales-rep/sales-rep.routes').then((m) => m.salesRepRoutes),
      },
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./features/cms/home-page/home-page.component').then((m) => m.HomePageComponent),
      },
      {
        path: 'not-found',
        loadComponent: () => import('./features/cms/not-found-page/not-found-page.component').then((m) => m.NotFoundPageComponent),
      },
      {
        path: 'cms',
        loadChildren: () => import('./features/cms/cms-page/cms-page.routes').then((m) => m.routes),
      },
      // {
      //   path: 'blog',
      //   loadChildren: () => import('./features/cms/cms-features/blog/blog.module').then((m) => m.BlogModule),
      // },
      // {
      //   path: 'not-found',
      //   loadChildren: () => import('./features/cms/cms-features/not-found/not-found.module').then((m) => m.NotFoundModule),
      // },
      {
        path: '',
        loadChildren: () => import('./features/catalog/catalog.routes').then((m) => m.catalogRoutes),
      },
      // {
      //   path: 'product/:id',
      //   loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      // },
      // {
      //   path: 'page/:id',
      //   loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      // },
      {
        path: '**',
        loadChildren: () => import('./features/catalog/catalog.routes').then((m) => m.catalogRoutes),
      },
    ],
  },
];
