import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { CmsService } from '../../../services/cms/cms.service';
import { UrlPathPipe } from '../../../pipes/url-path.pipe';

@Component({
  selector: 'app-footer-menu2',
  templateUrl: './footer-menu2.component.html',
  styleUrls: ['./footer-menu2.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, RouterLink, UrlPathPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMenu2Component {
  cmsMenu = inject(CmsService).getMenu().result;
}
