import { Component, inject, Inject, Input, OnInit, PLATFORM_ID, Signal } from '@angular/core';
import { Article } from '@lobos/common-v3';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AsyncPipe, isPlatformBrowser, NgClass, NgFor, NgIf } from '@angular/common';
import { ArticlePriceComponent } from '../../../article-price/article-price.component';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { GlobalStore } from '../../../../store/global.store';
import { SuedoArticle } from '../../../../interfaces/suedo-article.interface';
import { UrlPathPipe } from '../../../../pipes/url-path.pipe';

@UntilDestroy()
@Component({
  selector: 'app-comparison-flyout',
  templateUrl: './comparison-flyout.component.html',
  styleUrls: ['./comparison-flyout.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgClass, RouterLink, NgIf, NgFor, ArticlePriceComponent, AsyncPipe, UrlPathPipe],
})
export class ComparisonFlyoutComponent implements OnInit {
  @Input() public classes: string = '';
  public mobile: boolean | undefined;

  store = inject(GlobalStore);

  compareItems: Signal<SuedoArticle[]> = this.store.compareItems;


  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window.screen.width < 1279) {
      this.mobile = true;
    }
  }

  remove(article: Article) {
    this.store.removeCompareItem(article);
  }
}
