import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ShellComponent } from '../shell/shell.component';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, ShellComponent],
})
export class RootComponent {}
