import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver implements Resolve<boolean> {
  private readonly response: Response;

  // TODO: Switch to Response token from server token
  constructor(
    @Optional() @Inject('RESPONSE') response: Response,
    private translocoService: TranslocoService,
    private router: Router,
  ) {
    this.response = response;
  }

  public async resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    const lang: string = route.params?.['lang'] ?? this.translocoService.getDefaultLang();
    if (!(this.translocoService.getAvailableLangs() as string[]).includes(lang)) {
      await this.languageNotFound();
      return false;
    }

    this.translocoService.setActiveLang(lang);
    return true;
  }

  private async languageNotFound(): Promise<void> {
    // this will only be sent in SSR environment
    if (this.response) {
      return this.response.redirect(302, '/');
    }

    await this.router.navigate(['/']);
  }
}
