import { inject, Injectable } from '@angular/core';
import { PageTracking, TrackingAdapterInterface } from '@lobos/common-v3';
import { LogService } from '../../../logging/log.service';
import { isPlatformBrowserProvider } from '../../../../providers/is-platform-browser.provider';
import { PlausibleEvent, PlausibleOptions } from './plausible.types';
import { EventTrackingInterface } from '@lobos/common-v3/src/lib/tracking/event-tracking.interface';
import { PlausibleLoaderService } from './plausible-loader.service';
import { GlobalStore } from '../../../../store/global.store';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    plausible: Function;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlausibleAdapter implements TrackingAdapterInterface {
  logService = inject(LogService);
  isPlatformBrowser = isPlatformBrowserProvider();
  plausibleLoaderService = inject(PlausibleLoaderService);
  store = inject(GlobalStore);

  constructor() {
    if (this.isPlatformBrowser && !window.plausible) {
      this.plausibleLoaderService.addPlausibleToDom();
    }
  }

  public async trackEvent<T extends EventTrackingInterface>(event: T): Promise<void> {
    if (event.name === 'purchase') {
      this.event(event.name, {
        revenue: {
          currency: 'USD',
          amount: (event.payload as any).ecommerce.value,
        },
        props: { 'is-salesrep': !!this.store.user()?.lngP48SalesRepID || !!this.store.user()?.oP48SalesRepForUser?.lngP48SalesRepID },
      });
      return;
    }

    this.event(event.name, {
      props: { 'is-salesrep': !!this.store.user()?.lngP48SalesRepID || !!this.store.user()?.oP48SalesRepForUser?.lngP48SalesRepID },
    });
  }

  public async trackPage<T extends PageTracking>(page: T): Promise<void> {
    this.logService.log('GTM trackPage', page);
    this.event('pageview', {
      props: { 'is-salesrep': !!this.store.user()?.lngP48SalesRepID || !!this.store.user()?.oP48SalesRepForUser?.lngP48SalesRepID },
    });
  }

  private event(event: PlausibleEvent | null, options?: PlausibleOptions) {
    if (this.isPlatformBrowser && window.plausible && event) {
      console.log('plausible trackEvent');
      window.plausible(event, options);
    } else if (this.isPlatformBrowser && !window.plausible) {
      console.error('window.plausible not available');
    }
  }
}
