import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(private _http: HttpClient) {}

  search(searchTerm: string) {
    return this._http.get<any>('/api/search/' + encodeURIComponent(searchTerm)); // TODO replace any
  }
}
