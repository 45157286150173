<!-- start topbar navigation -->
<ng-container *transloco="let t; scope: 'core'">
  <div class="hidden md:block js-close-subnav bg-gray-200">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1 flex justify-between items-center flex-col md:flex-row"
         aria-label="Topbar">
      <div class="hidden md:items-center w-full md:w-auto md:flex"></div>
      <div class="flex items-end justify-end w-full md:w-auto">
        <div class="ml-0 md:ml-10 space-x-4 flex items-center">
          <span class="relative z-0 inline-flex -ml-2 md:ml-0">
            <button
              *ngFor="let lang of languages().data | orderBy: ['sIsoCode']; let last = last"
              (click)="changeLanguage(lang.sIsoCode)"
              type="button"
              [class.border-r.border-black]="!last"
              [class.text-primary]="lang.sIsoCode === transloco.getActiveLang()"
              class="relative inline-flex items-center px-2 border-r border-black text-sm md:text-xs hover:text-primary focus:outline-none focus:text-primary uppercase"
            >
              {{ lang.sIsoCode }}
            </button>
          </span>

          <a [routerLink]="[t('core.header.download.link') | urlPath]" class="text-xs hover:text-primary">
            {{ t("core.header.download.text") }}
          </a>
          <a
            *ngIf="store.isLoggedIn() === false; else isAuthenticated"
            (click)="openLoginModal()"
            class="text-xs hover:text-primary cursor-pointer"
          >
            {{ t("core.header.menu.login") }}
          </a>
          <ng-template #isAuthenticated>
            <a
              [routerLink]="'profile/my-profile' | urlPath"
              *ngIf="store.user() as user"
              class="text-xs hover:text-primary"
            >{{ user.oContact.sFirstName + " " + user.oContact.sLastName }}</a
            >
          </ng-template>
        </div>
      </div>
    </nav>
  </div>
</ng-container>
<!-- end topbar navigation -->
