<ng-container *transloco="let t; scope: 'core'">
  <ng-container
    *ngIf="{
      isLoggedIn: store.isLoggedIn(),
      cart: store.activeCart()?.oSalesMaster,
      authUser: store.user(),
      shtPL1EcommDefaultPrice: store.user()?.oCustomer?.shtPL1EcommDefaultPrice
    } as context"
  >
    <div class="md:relative" [ngClass]="[classes, mobile ? '' : 'js-show-info']">
      <a
        [routerLink]="'checkout/cart/' + context.cart?.lngOrderID | urlPath"
        class="text-xl hover:text-primary flex items-center relative"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        <span class="text-xs font-bold text-white absolute -translate-y-1/2 top-1/2 -right-3 bg-primary px-1 rounded-full">{{
            (store.activeCart()?.oSalesItemList | cartItemIsMaster).length
          }}</span>
        <span class="sr-only">{{ t("core.header.flyout.cart.button.checkout") }}</span>
      </a>
      <div *ngIf="context.cart as cart" class="js-info js-cart absolute translate-y-0 md:top-full -right-3 z-30 hidden">
        <div
          *ngIf="store.activeCart()?.oSalesItemList?.length"
          class="@@profil bg-white mt-4 max-h-500px md:max-h-50vh shadow rounded-md overflow-auto pb-118px relative"
        >
          <div class="w-screen sm:w-470px md:w-640px p-4 bg-white">
            <div *ngIf="context.isLoggedIn" class="sm:pl-3">
              <div class="flex justify-between mb-4">
                <p class="font-bold text-lg">{{ t("core.header.flyout.cart.heading") }}</p>
                <div class="flex space-x-4 items-start">
                  <a
                    (click)="closeMenu.emit()"
                    [routerLink]="'profile/carts' | urlPath"
                    class="text-sm font-bold hover:text-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </a>
                  <button (click)="addCart()" class="js-show-cart text-left text-sm font-bold hover:text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <app-custom-select
                [value]="cart.lngOrderID.toString()"
                (valueChange)="changeCart($event)"
                *ngIf="carts()"
                [options]="carts()"
              ></app-custom-select>
            </div>
            <div *ngIf="store.activeCart()?.oSalesItemList?.length" class="sm:pl-3 my-4 @@table">
              <table class="w-full">
                <thead class="bg-gray-200">
                <tr>
                  <td class="p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.cart.amount") }}</td>
                  <td class="p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.cart.article") }}</td>
                  <td class="p-2 text-xs md:text-sm font-bold" *ngIf="context.shtPL1EcommDefaultPrice !== 1">
                    {{ t("core.header.flyout.cart.total") }}
                  </td>
                  <td class="p-2 text-xs md:text-sm font-bold" *ngIf="context.shtPL1EcommDefaultPrice !== 0">
                    {{ t("core.header.flyout.cart.price") }}
                  </td>
                  <td class="p-2 text-xs md:text-sm font-bold"></td>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                <ng-container *ngFor="let item of store.activeCart()?.oSalesItemList! | cartItemIsMaster">
                  <tr>
                    <td class="p-2 align-middle text-xs md:text-sm">{{ item.decQuantityOrdered }}</td>
                    <td class="p-2 align-middle text-xs md:text-sm">
                      <p class="hyphens-auto font-bold">
                        <a class="hover:text-primary" [routerLink]="['article' | urlPath, item.sArticleID]">{{
                            item.oArticle.sName
                          }}</a>
                      </p>
                      <p>{{ t("core.header.flyout.cart.article-number", { number: item.sArticleID }) }}</p>
                    </td>
                    <td class="p-2 align-middle text-xs md:text-sm" *ngIf="context.shtPL1EcommDefaultPrice !== 1">
                      <p class="font-bold">{{ item.decItemNetAmountFC | translocoCurrency }}</p>
                    </td>
                    <td class="p-2 align-middle text-xs md:text-sm" *ngIf="context.shtPL1EcommDefaultPrice !== 0">
                      <p class="font-bold">{{ item.decPrice | translocoCurrency }}</p>
                    </td>
                    <td class="p-2 align-middle">
                      <div class="flex items-center justify-center">
                        <button (click)="removeItem(item)" type="button" class="hover:text-primary">
                          <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <ng-container *ngFor="let childItem of store.activeCart()!.oSalesItemList | cartItemIsChild : item">
                    <tr style="border-top: 0">
                      <td class="p-2 align-middle text-xs md:text-sm">{{ childItem.decQuantityOrdered }}</td>
                      <td class="p-2 align-middle text-xs md:text-sm">
                        <p class="hyphens-auto font-bold">{{ childItem.oArticle.sName }}</p>
                        <p>{{ t("core.header.flyout.cart.article-number", { number: childItem.sArticleID }) }}</p>
                      </td>
                      <td class="p-2 align-middle text-xs md:text-sm" *ngIf="context.shtPL1EcommDefaultPrice !== 1">
                        <p class="font-bold">{{ childItem.decItemNetAmountFC | translocoCurrency }}</p>
                      </td>
                      <td class="p-2 align-middle text-xs md:text-sm" *ngIf="context.shtPL1EcommDefaultPrice !== 0">
                        <p class="font-bold">{{ childItem.decPrice | translocoCurrency }}</p>
                      </td>
                      <td class="p-2 align-middle"></td>
                    </tr>
                  </ng-container>
                </ng-container>
                </tbody>
              </table>
            </div>
            <div class="w-full bg-white rounded-md -bottom-5 left-0 fixed px-4 sm:px-8 pb-4">
              <div *ngIf="cart.decTotalOrderNetAmount" class="flex border-t border-black flex-col my-4 pt-4 @@table">
                <p *ngIf="store.isLoggedIn() && cart.oShippingCondition.sNameFL" class="text-sm flex justify-between">
                  <span>
                    {{ cart.oShippingCondition.sNameFL }}
                  </span>
                  <span>{{
                      cart.decPostage !== 0 ? (cart.decPostage | translocoCurrency) : t("core.header.flyout.cart.free-shipping")
                    }}</span>
                </p>

                <p class="text-sm font-bold flex justify-between">
                  <span>{{ t("core.header.flyout.cart.total") }}</span>
                  <span>{{ cart.decTotalOrderNetAmount | translocoCurrency }}</span>
                </p>
                <p class="text-sm flex justify-between">
                  <span>
                    {{ t("core.header.flyout.cart.vat", { vat: 8.1 }) }}
                    <!-- @todo not hardcoded in translate -->
                  </span>
                  <span>{{ cart.decTotalOrderTax | translocoCurrency }}</span>
                </p>
              </div>

              <div class="w-180px">
                <a
                  (click)="closeMenu.emit()"
                  [routerLink]="['checkout/cart' | urlPath, cart.lngOrderID]"
                  class="w-full mt-2 inline-flex items-center px-1.5 md:px-2.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-primary hover:bg-secondary focus:bg-secondary"
                >
                  <span class="text-base font-bold text-white ml-0 mr-auto">{{ t("core.header.flyout.cart.button.checkout") }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!store.activeCart()?.oSalesItemList?.length" class="mt-4 shadow rounded-md overflow-hidden">
          <div class="w-83vw sm:w-368px p-4 bg-white">
            <p class="text-sm text-right @@empty">{{ t("core.header.flyout.cart.empty") }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
