import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LoginModalComponent } from '../../login-modal/login-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { GlobalStore } from '../../../store/global.store';
import { AlternateVersionService } from '../../../services/alternate-version/alternate-version.service';
import { LanguageService } from '../../../services/language/language.service';
import { UrlPathPipe } from '../../../pipes/url-path.pipe';
import { OrderByPipe } from '../../../pipes/order-by.pipe';

@Component({
  selector: 'app-topbar-navigation',
  templateUrl: './topbar-navigation.component.html',
  styleUrls: ['./topbar-navigation.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, NgFor, RouterLink, UrlPathPipe, OrderByPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarNavigationComponent {
  readonly store = inject(GlobalStore);
  alternateVersionService = inject(AlternateVersionService);
  languageService = inject(LanguageService);
  transloco = inject(TranslocoService);

  languages = this.languageService.getLanguages().result;

  constructor(
    private dialog: MatDialog,
  ) {}

  openLoginModal(): void {
    this.dialog.open(LoginModalComponent, { data: { forgotPassword: false } });
  }

  changeLanguage(lang: string) {
    this.alternateVersionService.routeToVersion(lang);
  }
}
