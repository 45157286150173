import { APP_INITIALIZER, inject, Injector } from '@angular/core';
import { InjectorClass } from '../services/injector/injector.class';

export const provideInjectorClass = () => {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const injector = inject(Injector);
      InjectorClass.set(injector);
      return () => {};
    },
  };
};
