import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { GlobalStore } from '../store/global.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, filter, map, tap } from 'rxjs';
import { FeatureStatus } from '../store/model/feature-status.enum';
import { UrlHelperService } from '../services/url/url-helper.service';
import { Location } from '@angular/common';

export const authGuard: CanActivateFn = () => {
  const store = inject(GlobalStore);
  const router = inject(Router);
  const location = inject(Location);
  const urlHelper = inject(UrlHelperService);

  return combineLatest([toObservable(store.isLoggedIn), toObservable(store.authStatus)]).pipe(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter(([_isLoggedIn, authStatus]) => authStatus === FeatureStatus.initialized),
    map(([isLoggedIn]) => isLoggedIn),
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate([urlHelper.getUrlPath('/')], { queryParams: { redirect: location.path() } });
      }
    }),
  );
};
