import { inject, Injectable } from '@angular/core';
import { first, forkJoin, Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Article, FavoriteItem, Price } from '@lobos/common-v3';
import { FavoriteService } from './favorite.service';
import { Label, LABEL_PRINT_CONFIG, LabelMapperInterface, LabelPrintConfig, MapperAbstract } from '@lobos/label-print-v3';
import { ArticleService } from '../catalog/article.service';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { ImageHelperService } from '../catalog/image-helper.service';

@Injectable()
export class FavoriteMapper extends MapperAbstract implements LabelMapperInterface {
  config: LabelPrintConfig = inject(LABEL_PRINT_CONFIG);
  favoriteService = inject(FavoriteService);
  articleService = inject(ArticleService);
  translocoLocaleService = inject(TranslocoLocaleService);
  imageHelperService = inject(ImageHelperService);

  public filename(id: string): Observable<string> {
    return this.favoriteService.getFavoriteListById(id).result$.pipe(map((result) => result.data?.sListname || 'print'));
  }

  public map(id: string): Observable<Label[]> {
    return this.favoriteService.getFavoriteItems(id).result$.pipe(
      filter((res) => res.isSuccess),
      map((res) => res.data!.data),
      switchMap((favorites: FavoriteItem[]) =>
        forkJoin(
          favorites.map((favorite: FavoriteItem) =>
            this.articleService
              .getPrice({
                articleId: favorite.oArticle!.sArticleID as string,
                currency: 'CHF',
                factor1: 1,
                factor2: 1,
                factor3: 1,
                qty: favorite.oArticle!.lngSalesPriceUnit || 1,
                unit: favorite.oArticle!.sQuantityUnitSales || '',
              })
              .result$.pipe(
                filter((res) => res.isSuccess),
                first(),
                map((res) => res.data! as Price),
                map((price) => this.mapFavoriteToLabel(favorite, price)),
              ),
          ),
        ),
      ),
    );
  }

  private mapFavoriteToLabel(favorite: FavoriteItem, priceInfo: Price): Label {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const article: Article = favorite.oArticle!;

    const price = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountNetFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );
    //const discountedFrom = priceInfo?.decSpecialPriceNet ? this.decimal.transform(priceInfo.decBasePriceNet) : undefined;
    const discountedFrom = priceInfo?.decSpecialPriceNet ? priceInfo.decBasePriceNet : undefined;

    const inclNet = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountGrossFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const inclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceGross || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const exclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceNet || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    return {
      data: {
        code: article.sEAN,
        sku: article.sArticleID.toString(),
        title: article.sName,
        description: article.sDescription,
        price: price.toString(),
        discountedFrom: discountedFrom?.toString(),
        date: Date.now().toString(),
        unit: `${priceInfo?.lngSalesPriceUnit} ${priceInfo?.sQuantityUnit}`,
        currency: priceInfo?.sCurrencyCode,
        logoPath: this.config.logoPath,
        imgPath: this.imageHelperService.getArticleMainImage(article)?.toURL(),
        customFields: {
          inclNet: inclNet,
          inclGross: inclGross,
          exclGross: exclGross,
          customerArticleID: '', //customerArticleID?.sCustomerArticleID ? customerArticleID.sCustomerArticleID : '',
          articleType: article.sArticleCode1,
        },
      },
      amount: 1,
      print: false,
      type: 'data',
    };
  }
}
