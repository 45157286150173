import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { isPlatformBrowserProvider } from './providers/is-platform-browser.provider';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  isBrowser = isPlatformBrowserProvider();

  getTranslation(lang: string) {
    if (environment.loadTranslationFromServer) {
      if (!lang.includes('/')) {
        lang = 'core/' + lang;
      }
      if (this.isBrowser) {
        return this.http.get<Translation>(`/api/translation/${lang}`);
      }
      return this.http.get<Translation>(`${environment.api.host}/api/translation/${lang}`);
    }
    return this.http.get<Translation>(`${environment.host}/assets/i18n/${lang}.json`);
  }
}
