import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { TranslocoModule } from '@jsverse/transloco';
import { SalesRepService } from '../../services/sales-rep/sales-rep.service';
import { BreakpointService } from '../../services/breakpoint/breakpoint-helper.service';
import { GlobalStore } from '../../store/global.store';

@Component({
  selector: 'app-sales-rep-banner',
  templateUrl: './sales-rep-banner.component.html',
  styleUrls: ['./sales-rep-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, NgIf, TextButtonComponent, AsyncPipe],
})
export class SalesRepBannerComponent {
  readonly store = inject(GlobalStore);
  breakpointService = inject(BreakpointService);
  salesRepService = inject(SalesRepService);
  logoutFromCustomer = this.salesRepService.logoutFromCustomer();
  logoutFromCustomerDef = this.logoutFromCustomer.result;

  isMobile$ = this.breakpointService.mobile;
}
