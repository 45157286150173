<!-- start info aussendienst -->
@if (store.user()?.sP48SalesRepForUser?.length) {
  <ng-container *transloco="let t; scope: 'core'">
    <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-70">
      <div class="max-w-4xl lg:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="pl-2 rounded-md bg-primary shadow-sm">
          <div class="flex items-center justify-between flex-wrap">
            <div *ngIf="(isMobile$ | async) === false" class="w-0 flex-1 flex items-center gap-4 pb-2">
              <div>
                <app-text-button (click)="logoutFromCustomer.mutate({})" [text]="t('core.sales-rep-banner.logout')"></app-text-button>
              </div>
              <div class="flex mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p class="ml-3 text-white truncate">
                  {{
                    t("core.sales-rep-banner.title", {
                      company: store.user()!.oCustomer.sCompany1,
                      customerId: store.user()!.oCustomer.lngCustomerID,
                      place: store.user()!.oCustomer.sCity
                    })
                  }}
                </p>
              </div>
            </div>

            <ng-container *ngIf="isMobile$ | async">
              <div class="flex pb-2 items-center">
                <div>
                  <app-text-button (click)="logoutFromCustomer.mutate({})" [text]="t('core.sales-rep-banner.logout')"></app-text-button>
                </div>
                <p class="ml-3 mt-2 text-white">
                  {{
                    t("core.sales-rep-banner.title", {
                      company: store.user()!.oCustomer.sCompany1,
                      customerId: store.user()!.oCustomer.lngCustomerID,
                      place: store.user()!.oCustomer.sCity
                    })
                  }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
}
<!-- end info aussendienst -->
