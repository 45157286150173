import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EqualValidator } from '../../validators/equal.validator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalStore } from '../../store/global.store';
import { InputComponent } from '../form/input/input.component';
import { GetErrorPipe } from '../../pipes/get-error.pipe';
import { TextButtonComponent } from '../button/text-button/text-button.component';
import { TranslocoDirective } from '@jsverse/transloco';

export interface PasswordResetModalInterface {
  guiId: string;
}

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss'],
  standalone: true,
  imports: [
    InputComponent,
    GetErrorPipe,
    TextButtonComponent,
    TranslocoDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetModalComponent {
  store = inject(GlobalStore);

  public form = new FormGroup({
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    passwordConfirm: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  }, {
    validators: [EqualValidator('password', 'passwordConfirm')],
  });

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<PasswordResetModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordResetModalInterface,
  ) {
  }

  public async changePassword(): Promise<void> {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    try {
      await this.store.resetPassword({
        sNewPassword: this.form.value.password!,
        sRegID: this.data.guiId!,
      });
      this.dialogRef.close();
    } catch (_) {
      this.form.controls.password.setErrors({ backend: true });
      this.form.controls.passwordConfirm.setErrors({ backend: true });
    }
  }
}
