import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { FooterLogosComponent } from './footer-logos/footer-logos.component';
import { FooterMenu2Component } from './footer-menu2/footer-menu2.component';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { FooterAddressComponent } from './footer-address/footer-address.component';
import { CmsService } from '../../services/cms/cms.service';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [FooterAddressComponent, FooterMenuComponent, FooterMenu2Component, FooterLogosComponent, FooterBottomComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  footer = inject(CmsService).getFooter().result;
}
