import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideInjectorClass } from './providers/set-injector-class';
import { environment } from '../environments/environment';
import { ScannerModule, ZbarAdapter, ZbarConfig, ZbarSymbologyEnum } from '@lobos/scanner-adapter-zbar';
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_SCROLL_STRATEGY, MatDialogConfig } from '@angular/material/dialog';
import { provideTransloco } from '@jsverse/transloco';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { customHeaderInterceptor } from './interceptors/custom-header.interceptor';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideMarkdown } from 'ngx-markdown';
import { MapperAbstract, provideLabelPrint } from '@lobos/label-print-v3';
import { FavoriteMapper } from './services/label-print/favorite.mapper';
import { provideRegisterTrackingAdapter } from './services/tracking/tracking.provider';
import { PlausibleAdapter } from './services/tracking/adapters/plausible/plausible.adapter';
import { PLAUSIBLE_CONFIG } from './services/tracking/adapters/plausible/plausible.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideInjectorClass(),
    provideHttpClient(withFetch(), withInterceptors([customHeaderInterceptor])),
    provideRouter(appRoutes, withComponentInputBinding()),
    {
      provide: PLAUSIBLE_CONFIG,
      useValue: { id: environment.tracking.plausibleDomain },
    },
    provideRegisterTrackingAdapter(PlausibleAdapter),
    provideAnimations(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        de: 'de-CH',
        fr: 'fr-CH',
      },
    }),
    provideTransloco({
      config: {
        availableLangs: environment.availableLang,
        defaultLang: environment.defaultLocale,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoMessageformat({
      locales: ['de-CH', 'fr-CH'],
    }),
    importProvidersFrom(
      ScannerModule.forRoot<ZbarConfig>({
        adapter: {
          provide: ZbarAdapter,
          useClass: ZbarAdapter,
        },
        scanFrequency: 750,
        adapterConfig: {
          engineLocation: 'assets/wasm',
          symbology: [ZbarSymbologyEnum.EAN13, ZbarSymbologyEnum.QR, ZbarSymbologyEnum.CODE128],
        },
      }),
    ),
    provideMarkdown(),
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        backdropClass: 'overlay',
      } as MatDialogConfig,
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      useFactory: (scrollStrategyOptions: ScrollStrategyOptions) => scrollStrategyOptions.noop,
      deps: [ScrollStrategyOptions],
    },
    provideLabelPrint({
      images: environment.images,
      logoPath: '/assets/images/layout/Logo_Suedo_Jasa_55px-h_Web.png',
      mapper: {
        provide: MapperAbstract,
        useClass: FavoriteMapper,
      },
    })
  ],
};
