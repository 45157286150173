import { FocusableOption } from '@angular/cdk/a11y';
import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[focusable]',
  standalone: true,
})
export class FocusableDirective implements FocusableOption {
  @HostBinding('tabindex') tabindex = -1;
  @HostBinding('role') role = 'list-item';

  public disabled: boolean = false;

  constructor(private element: ElementRef) {}

  public getLabel(): string {
    return this.element.nativeElement.innerText;
  }

  public focus() {
    this.element.nativeElement.focus();
  }
}
