import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import * as Sentry from '@sentry/angular';

Sentry.init({
  enabled: environment.production,
  release: environment.version,
  dsn: environment.sentry.sentry_dns,
  environment: environment.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', environment.host],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerSwiperElements();

bootstrapApplication(AppComponent, { ...appConfig, providers: [...appConfig.providers, environment.production ? [] : provideQueryDevTools()] }).catch((err) =>
  console.error(err),
);
