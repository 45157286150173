import { inject, Injectable } from '@angular/core';
import { injectQuery } from '@ngneat/query';
import { HttpClient } from '@angular/common/http';
import { Catalog } from '@lobos/common-v3';
import { map } from 'rxjs';
import { sortBy } from 'lodash';

export interface NavigationInterface {
  title: string;
  url: string;
  //url: string | string[];
  specialClass?: string;
  isOpen?: boolean;
  subNavigation?: NavigationInterface[];
}

@Injectable({ providedIn: 'root' })
export class ShopNavigationService {
  private _http = inject(HttpClient);
  private _query = injectQuery();

  getMenuTree() {
    return this._query({
      queryKey: ['menu'] as const,
      queryFn: () =>
        this._http.get<Catalog[]>('/api/catalog/menu').pipe(
          map((res) => {
            const parents: { [key: string]: Catalog[] } = {};
            for (const group of res) {
              if (typeof group.sUrlPath === 'string') {
                const parentGroupUrlPath = group.sUrlPath.replace(/\/[^/]*\/$/, '/');
                if (!parents[parentGroupUrlPath]) {
                  parents[parentGroupUrlPath] = [];
                }
                parents[parentGroupUrlPath].push(group);
              }
            }

            const root: Catalog | undefined = res.find((group: Catalog) => group.sType === 'K');
            if (!root) {
              return [];
            }

            return this.getGroupsForParent(root.sUrlPath, parents);
          }),
        ),
    });
  }

  private getGroupsForParent(parentUrlPath: string | string[], parents: { [key: string]: Catalog[] }): NavigationInterface[] {
    if (typeof parentUrlPath === 'string') {
      const group: Catalog[] = parents[parentUrlPath] || [];

      return this.mapGroupToNav(sortBy(group, 'lngItemId'), parents);
    } else {
      const group: Catalog[] = parents[parentUrlPath[0]] || [];

      return this.mapGroupToNav(sortBy(group, 'lngItemId'), parents);
    }
  }

  private mapGroupToNav(catalog: Catalog[], parents: { [key: number]: Catalog[] }): NavigationInterface[] {
    return catalog.map((group: Catalog) => ({
      title: group.sTitle,
      url: this.getCatalogUrlPath(group) as string,
      subNavigation: this.getGroupsForParent(group.sUrlPath, parents),
    }));
  }

  private getCatalogUrlPath(category: Catalog) {
    return category?.sUrlPath;

    // TODO Url-Pfad mit Transloco verwenden und '/online-shop/ entfernen'
    // return UrlPathHelperService.getUrlPath(category?.sUrlPath, this.translocoService.getActiveLang());
  }
}
