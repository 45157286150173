import { inject, Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { NavigationInterface } from '../services/navigation/shop-navigation.service';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'routeActive',
  standalone: true,
})
export class RouteActivePipe implements PipeTransform {
  translocoService = inject(TranslocoService);

  constructor(private router: Router, private location: Location) {}
  public transform(navigation: NavigationInterface): Observable<boolean> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      shareReplay(1),
      startWith(undefined),
      map(() => this.isInPath(this.location.path(), navigation.url)),
    );
  }

  isInPath(path: string, url: string): boolean {
    return (path + '/').startsWith(url.replace(/^\/suedo/, `/${this.translocoService.getActiveLang()}`));
  }
}
