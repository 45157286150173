import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { ServiceNavigationComponent } from './service-navigation/service-navigation.component';
import { SearchComponent } from './search/search.component';
import { RouterLink } from '@angular/router';
import { TopbarNavigationComponent } from './topbar-navigation/topbar-navigation.component';
import { ShopNavigationService } from '../../services/navigation/shop-navigation.service';
import { BreakpointService } from '../../services/breakpoint/breakpoint-helper.service';
import { UrlPathPipe } from '../../pipes/url-path.pipe';

export interface NavigationInterface {
  title: string;
  url: string;
  specialClass?: string;
  isOpen?: boolean;
  subNavigation?: NavigationInterface[];
}

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TopbarNavigationComponent,
    RouterLink,
    SearchComponent,
    ServiceNavigationComponent,
    MainNavigationComponent,
    MobileNavigationComponent,
    AsyncPipe,
    UrlPathPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  menu = inject(ShopNavigationService).getMenuTree().result;
  isMobile$ = this.breakpointService.mobile;

  mobileMenuOpen = false;

  public logoPath: string = environment.logoPath;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private breakpointService: BreakpointService,
  ) {
  }

  private showMobileNav(): void {
    this.document.querySelector('body')!.classList.add('h-screen');
    this.document.querySelector('body')!.classList.add('overflow-hidden');
    this.document.querySelector('.js-mobile-nav')!.classList.add('translate-x-0');
    this.document.querySelector('.js-mobile-nav')!.classList.remove('translate-x-full');
  }

  private hideMobileNav(): void {
    this.document.querySelector('body')!.classList.remove('h-screen');
    this.document.querySelector('body')!.classList.remove('overflow-hidden');
    this.document.querySelector('.js-mobile-nav')!.classList.remove('translate-x-0');
    this.document.querySelector('.js-mobile-nav')!.classList.add('translate-x-full');
  }

  toggleMobileMenu() {
    if (this.mobileMenuOpen) {
      this.hideMobileNav();
      this.mobileMenuOpen = false;
    } else {
      this.showMobileNav();
      this.mobileMenuOpen = true;
    }
  }
}
