import { TrackingFactory } from './tracking.factory';
import { APP_INITIALIZER, inject } from '@angular/core';
import { TrackingAdapterInterface } from '@lobos/common-v3';

export const provideRegisterTrackingAdapter = (adapter: any) => {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const trackingFactory = inject(TrackingFactory);
      const injectedAdapter: TrackingAdapterInterface = inject(adapter);
      trackingFactory.register(injectedAdapter);
      return () => {};
    },
  };
};
