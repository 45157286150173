import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { LoginModalComponent } from '../../../login-modal/login-modal.component';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgClass, NgIf } from '@angular/common';
import { GlobalStore } from '../../../../store/global.store';
import { UrlPathPipe } from '../../../../pipes/url-path.pipe';
import { TranslocoDirective } from '@jsverse/transloco';
import { HasAccessPipe } from '../../../../pipes/has-access.pipe';
import { ACCESS_ID } from '../../../../services/authorization/access.service';

@Component({
  selector: 'app-user-flyout',
  templateUrl: './user-flyout.component.html',
  styleUrls: ['./user-flyout.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    NgIf,
    RouterLinkActive,
    UrlPathPipe,
    TranslocoDirective,
    HasAccessPipe,
  ],
})
export class UserFlyoutComponent {
  @Input() public classes: string = '';
  @Output() userItemClicked: EventEmitter<any> = new EventEmitter<any>();
  store = inject(GlobalStore);

  accessId: typeof ACCESS_ID = ACCESS_ID;

  constructor(private dialog: MatDialog, private router: Router) {
  }

  openLoginModal(): void {
    this.dialog.open(LoginModalComponent, { data: { forgotPassword: false } });
  }

  public async logout(): Promise<void> {
    await this.store.logout();
    await this.router.navigate(['/']);
  }
}
