import { Injectable } from '@angular/core';
import { Template } from '@lobos/label-print-v3';
import { Suedo3x8QrRenderer } from './suedo3x8_qr.renderer';

@Injectable({
  providedIn: 'root',
})
export class Suedo3x17QrRenderer extends Suedo3x8QrRenderer {
  public override template(): Template {
    return {
      id: 'suedo3x17',
      title: 'profile.label-print.domain.template.suedo3x17_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 16.9 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 17 },
    };
  }

  override async addDate(date: string, x: number, y: number): Promise<void> {
    const paddingBox = this.addPadding(x, y + this.boxHeight * (7 / 10), this.boxWidth * (1 / 4), this.boxHeight * (1 / 5));
    this.jspdf.drawText(date, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'normal');
  }

  override async addArticleNumber(articleNumber: string, x: number, y: number): Promise<void> {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y,
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(articleNumber, paddingBox.x, paddingBox.y, paddingBox.width, 5, 1, 'bold');
  }

  override async addArticleType(articleType: string | undefined, x: number, y: number): Promise<void> {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y + this.boxHeight * (1 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(articleType ? articleType : '', paddingBox.x, paddingBox.y, paddingBox.width, 5, 1, 'normal');
  }

  override async addTitle(title: string, x: number, y: number): Promise<void> {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y + this.boxHeight * (2 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(title, paddingBox.x, paddingBox.y, paddingBox.width, 5, 2, 'normal');
  }

  override async addVat(x: number, y: number): Promise<void> {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (3.5 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(
      this.configuration?.extra?.hidePrice ? '' : this.configuration?.extra?.showInclPrice ? 'inkl. MwSt.' : 'exkl. MwSt.',
      paddingBox.x,
      paddingBox.y,
      paddingBox.width,
      5,
      1,
      'normal',
    );
  }

  override async addQrCode(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    return super.addQrCode(code, x, y, maxWidth, maxHeight + 3);
  }
}
