<nav class="flex xl:space-x-2 2xl:space-x-4" aria-label="Bottom">
  <ng-container *ngFor="let navigation of navigationMenu">
    <ng-container *ngIf="navigation?.subNavigation?.length; else noSubNav">
      <div>
        <a
          [appHoverMenu]="{
            subnav: '.js-subnav',
            hideSubnav: '.js-hide-subnav',
            closeSubnav: '.js-close-subnav',
            activeSelector: '.block'
          }"
          [ngClass]="(navigation | routeActive | async) ? 'font-bold' : ''"
          [routerLink]="navigation.url | urlPath"
          (click)="closeDropdown()"
          class="js-show-subnav inline-flex items-center text-sm text-white hover:opacity-60 focus:opacity-60 whitespace-nowrap"
        >
          <span>{{ navigation.title }}</span>
          <svg
            class="ml-1 h-5 w-5 group-hover:text-primary"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
        <div
          class="hidden absolute z-10 top-full inset-x-0 transform shadow-lg bg-white js-subnav max-h-nav overflow-y-auto">
          <div
            class="max-w-7xl mx-auto columns-1 md:columns-2 lg:columns-4 gap-2 px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-5 xl:py-6">
            <ng-container *ngFor="let secondLevelNavigation of navigation.subNavigation">
              <div class="mb-4 pr-4">
                <a
                  (click)="closeDropdown()"
                  [routerLink]="secondLevelNavigation.url | urlPath"
                  [ngClass]="(secondLevelNavigation | routeActive | async) ? 'text-primary' : ''"
                  class="-my-3 py-2 inline-block w-full hover:text-primary"
                >
                  <span class="text-sm font-bold">{{ secondLevelNavigation.title }}</span>
                </a>
                @if (secondLevelNavigation.subNavigation) {
                  <a
                    *ngFor="let thirdLevelNavigation of secondLevelNavigation.subNavigation"
                    [ngClass]="(thirdLevelNavigation | routeActive | async) ? 'text-primary' : ''"
                    [routerLink]="thirdLevelNavigation.url | urlPath"
                    (click)="closeDropdown()"
                    class="-my-3 py-2 inline-block w-full hover:text-primary"
                  >
                    <span class="text-sm">{{ thirdLevelNavigation.title }}</span>
                  </a>
                }
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noSubNav>
      <a [routerLink]="navigation.url | urlPath" class="js-hide-subnav inline-block text-white hover:opacity-60 focus:opacity-60">
        <span class="text-sm">{{ navigation.title }}</span>
      </a>
    </ng-template>
  </ng-container>
</nav>
