import { Component, Inject, Input } from '@angular/core';
import { NavigationInterface } from '../header.component';
import { DOCUMENT, NgFor, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { HoverMenuDirective } from '../../../directives/hover-menu.directive';
import { RouteActivePipe } from '../../../directives/route-active.pipe';
import { UrlPathPipe } from '../../../pipes/url-path.pipe';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, RouterLink, AsyncPipe, HoverMenuDirective, RouteActivePipe, UrlPathPipe],
})
export class MainNavigationComponent {
  @Input() navigationMenu!: NavigationInterface[];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  closeDropdown() {
    this.document.querySelector('.js-subnav.block')?.classList.add('hidden');
    this.document.querySelector('.js-subnav.block')?.classList.remove('block');
  }
}
