import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DomHelperService } from '../services/dom/dom-helper.service';

@Directive({
  selector: '[appToggleMenu]',
  standalone: true,
})
export class ToggleMenuDirective {
  @Input() appToggleMenu!: {
    subnav: string;
    border: boolean;
  };

  @HostListener('click', ['$event.target'])
  public click(element: HTMLElement) {
    const item: HTMLElement = this.elementRef.nativeElement;
    const buttonEl: HTMLButtonElement = item.querySelector('button')!;
    if (element.tagName !== 'A') {
      const mobileSubnavToBeActivated = item.parentNode!.querySelector(this.appToggleMenu.subnav);
      if (mobileSubnavToBeActivated!.classList.contains('hidden')) {
        DomHelperService.showContent(mobileSubnavToBeActivated);
        buttonEl.classList.add('rotate-180');
        buttonEl.classList.add('text-primary');
        item.querySelector('a')!.classList.add('text-primary');
        if (this.appToggleMenu.border) {
          item.classList.remove('border-b');
        }
      } else {
        DomHelperService.hideContent(mobileSubnavToBeActivated);
        buttonEl.classList.remove('rotate-180');
        buttonEl.classList.remove('text-primary');
        item.querySelector('a')!.classList.remove('text-primary');
        if (this.appToggleMenu.border) {
          item.classList.add('border-b');
        }
      }
    }
  }

  constructor(private elementRef: ElementRef) {}
}
