import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Article, MultiIndexSearchResponse } from '@lobos/common-v3';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { SearchItemComponent } from '../search-item/search-item.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { SearchService } from '../../../services/search/search.service';
import { GlobalStore } from '../../../store/global.store';
import { FocusListDirective } from '../../../directives/focus-list.directive';
import { FocusableDirective } from '../../../directives/focusable.directive';
import { UrlHelperService } from '../../../services/url/url-helper.service';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
    RouterLink,
    SearchItemComponent,
    AsyncPipe,
    FocusListDirective,
  ],
})
export class SearchComponent implements OnInit {
  searchControl: UntypedFormControl = new UntypedFormControl('');
  searchResult: MultiIndexSearchResponse | undefined;
  showResults: boolean = false;
  hasFocus: boolean = false;
  @Output() searchItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild(FocusableDirective)
  private focusList: FocusableDirective | undefined;

  searchService = inject(SearchService);
  store = inject(GlobalStore);
  cdr = inject(ChangeDetectorRef);

  constructor(
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {
  }

  @HostListener('click')
  clickedInside() {
    this.hasFocus = true;
    this.showResults = true;
  }

  @HostListener('document:click')
  clickOut() {
    if (!this.hasFocus) {
      this.showResults = false;
    }
    this.hasFocus = false;
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        switchMap((searchTerm) => {
          if (searchTerm.trim() !== '') {
            return this.searchService.search(searchTerm);
          } else {
            return EMPTY;
          }
        }),
        tap((searchResult) => {
          this.searchResult = searchResult;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  addArticleToCart({
                     article,
                     quantity,
                   }: {
    article: Article;
    quantity: number
  }) {
    this.store.createCartItem({
      decQuantity: quantity,
      sArticleID: article.sArticleID as string,
      sQuantityUnit: article.sQuantityUnitSales,
      oArticle: article,
    });
  }

  openSearchDropdown() {
    this.hasFocus = true;
    this.showResults = true;
  }

  focusOut() {
    this.hasFocus = false;
  }

  articleRouterLinkClicked(event: Event) {
    this.searchItemClicked.emit();
    this.closeSearchDropdown(event);
  }

  focusEnterClick(event: Event, article: Article) {
    this.focusOut();
    this.closeSearchDropdown(event);
    this.router.navigate([this.urlHelper.getUrlPath('article'), article.sArticleID]);
  }

  closeSearchDropdown(event: Event) {
    event.stopPropagation();
    if (!this.hasFocus) {
      this.showResults = false;
    }
  }

  trackByArticleId(_: number, item: Article) {
    return item.sArticleID;
  }

  searchForSearchTerm(event: Event) {
    this.router.navigate([this.urlHelper.getUrlPath('search'), this.searchControl.value]);
    this.closeSearchDropdown(event);
  }

  focusEvent(event: KeyboardEvent) {
    if (['arrowdown'].includes(event.key.toLowerCase())) {
      this.focusList?.focus();
    }
    if (['enter'].includes(event.key.toLowerCase())) {
      this.searchForSearchTerm(event);
    }
  }
}
