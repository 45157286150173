import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { SalesRepBannerComponent } from '../sales-rep-banner/sales-rep-banner.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  standalone: true,
  imports: [HeaderComponent, FooterComponent, BreadcrumbComponent, SalesRepBannerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent {}
