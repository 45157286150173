import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Article } from '@lobos/common-v3';
import { ArticleScaledDiscountComponent } from '../../article-scaled-discount/article-scaled-discount.component';
import { QuantityInputComponent } from '../../quantity-input/quantity-input.component';
import { ArticlePriceComponent } from '../../article-price/article-price.component';
import { NgClass, NgIf } from '@angular/common';
import { ImageComponent } from '../../image/image.component';
import { RouterLink } from '@angular/router';
import { GlobalStore } from '../../../store/global.store';
import { SuedoArticle } from '../../../interfaces/suedo-article.interface';
import { UrlPathPipe } from '../../../pipes/url-path.pipe';
import { ArticleMainImagePipe } from '../../../pipes/article-main-image.pipe';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    ImageComponent,
    NgClass,
    ArticlePriceComponent,
    NgIf,
    QuantityInputComponent,
    ArticleScaledDiscountComponent,
    UrlPathPipe,
    ArticleMainImagePipe,
  ],
})
export class SearchItemComponent {
  @Input() article!: SuedoArticle;
  @Output() linkClicked: EventEmitter<Event> = new EventEmitter();
  @Output() buyItemClicked: EventEmitter<{
    article: Article;
    quantity: number
  }> = new EventEmitter();
  store = inject(GlobalStore);
  quantity: number = 1;
  priceIsZero: boolean = false;

  setPriceIsZero(value: boolean): void {
    setTimeout(() => (this.priceIsZero = value), 300);
  }

  articleRouterLinkClicked(event: Event) {
    this.linkClicked.emit(event);
  }

  addArticleToCart(article: Article) {
    this.buyItemClicked.emit({
      article,
      quantity: this.quantity,
    });
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }
}
