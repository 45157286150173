import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { CmsFooterContent } from '../../../services/cms/cms.interface';

@Component({
  selector: 'app-footer-address',
  templateUrl: './footer-address.component.html',
  styleUrls: ['./footer-address.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterAddressComponent {
  footer = input.required<CmsFooterContent>();
}
