import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { CartItem } from '@lobos/common-v3';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ModalConfirmComponent } from '../../../modal-confirm/modal-confirm.component';
import { isPlatformBrowser, NgClass, NgFor, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { CustomSelectComponent } from '../../../form/custom-select/custom-select.component';
import { RouterLink } from '@angular/router';
import { GlobalStore } from '../../../../store/global.store';
import { UrlPathPipe } from '../../../../pipes/url-path.pipe';
import { CartItemIsMasterPipe } from '../../../../pipes/cart-item-is-master.pipe';
import { CartItemIsChildPipe } from '../../../../pipes/cart-item-is-child.pipe';
import { AddEditCartModalComponent } from '../../../add-edit-cart-modal/add-edit-cart-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-cart-flyout',
  templateUrl: './cart-flyout.component.html',
  styleUrls: ['./cart-flyout.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    NgClass,
    RouterLink,
    CustomSelectComponent,
    NgFor,
    TranslocoLocaleModule,
    UrlPathPipe,
    CartItemIsMasterPipe,
    CartItemIsChildPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartFlyoutComponent implements OnInit {
  @Input() public classes: string = '';
  @Output() closeMenu: EventEmitter<void> = new EventEmitter();
  public mobile: boolean = false;
  store = inject(GlobalStore);
  carts = computed(() =>
    this.store.allCartHeaders().map((cart) => ({
      key: cart.lngOrderID.toString(),
      value: cart.sCartName,
    })),
  );

  constructor(
    private translocoService: TranslocoService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window.innerWidth < 1279) {
      this.mobile = true;
    }
  }

  addCart(): void {
    this.dialog.open(AddEditCartModalComponent, { data: { cartHeader: null } });
  }

  changeCart(id: string | number): void {
    this.store.changeActiveCart(id);
  }

  removeItem(item: CartItem): void {
    combineLatest([
      this.translocoService.selectTranslate('core.header.flyout.cart.modal-confirm.title'),
      this.translocoService.selectTranslate('core.header.flyout.cart.modal-confirm.message'),
    ])
      .pipe(
        switchMap(([title, message]) => {
          const dialogRef = this.dialog.open(ModalConfirmComponent, {
            width: '400px',
            data: {
              title,
              message,
            },
          });
          return dialogRef.afterClosed();
        }),
        filter((result: boolean) => result),
        tap(() => this.store.removeCartItem(item.lngOrderID, item.shtFixedItemID)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
