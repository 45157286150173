import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ArticleItemComponent } from '../../article-item/article-item.component';
import { NgFor, NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { SuedoArticle } from '../../../interfaces/suedo-article.interface';

@Component({
  selector: 'app-scanner-results',
  templateUrl: './scanner-results.component.html',
  styleUrls: ['./scanner-results.component.scss'],
  standalone: true,
  imports: [TranslocoModule, NgIf, NgFor, ArticleItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerResultsComponent {
  article = input<SuedoArticle>();
}
