import { inject, Injectable, signal } from '@angular/core';
import { isPlatformBrowserProvider } from '../../../../providers/is-platform-browser.provider';
import { DOCUMENT } from '@angular/common';
import { PLAUSIBLE_CONFIG, PlausibleConfig } from './plausible.config';
import { GlobalStore } from '../../../../store/global.store';

@Injectable({ providedIn: 'root' })
export class PlausibleLoaderService {
  isLoaded = signal(false);
  isPlatformBrowser = isPlatformBrowserProvider();
  document = inject(DOCUMENT);
  config: PlausibleConfig = inject(PLAUSIBLE_CONFIG);
  store = inject(GlobalStore);

  addPlausibleToDom(): void {
    if (!this.isPlatformBrowser || this.isLoaded()) {
      return;
    }

    const script = this.document.createElement('script');
    script.defer = true;
    script.id = 'plausible-script';
    script.setAttribute('data-domain', this.config.id);
    script.src = 'https://plausible.io/js/script.manual.pageview-props.revenue.js'; // for testing and local development use 'https://plausible.io/js/script.manual.pageview-props.revenue.local.js'
    this.document.head.insertBefore(script, this.document.head.firstChild);
    this.isLoaded.set(true);
  }
}
