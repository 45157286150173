import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { REQUEST } from '../../express.tokens';
import { environment } from '../../environments/environment';
import { TranslocoService } from '@jsverse/transloco';

export function customHeaderInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const clientReq = inject(REQUEST, { optional: true });
  const translocoService = inject(TranslocoService);
  req = req.clone({
    setHeaders: {
      ...(clientReq && clientReq.headers.cookie ? { Cookie: clientReq.headers.cookie } : {}),
      WebshopId: environment.api.webshopId.toString(),
      BusinessUnit: environment.api.branchKey,
      language: translocoService.getActiveLang(),
    },
  });
  return next(req);
}
