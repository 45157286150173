import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomHelperService } from '../../../services/dom/dom-helper.service';
import { CartFlyoutComponent } from './cart-flyout/cart-flyout.component';
import { FavoritesFlyoutComponent } from './favorites-flyout/favorites-flyout.component';
import { ComparisonFlyoutComponent } from './comparison-flyout/comparison-flyout.component';
import { UserFlyoutComponent } from './user-flyout/user-flyout.component';
import { ScannerButtonComponent } from '../../scanner/scanner-button/scanner-button.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-service-navigation',
  templateUrl: './service-navigation.component.html',
  styleUrls: ['./service-navigation.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    ScannerButtonComponent,
    UserFlyoutComponent,
    ComparisonFlyoutComponent,
    FavoritesFlyoutComponent,
    CartFlyoutComponent,
  ],
})
export class ServiceNavigationComponent implements AfterViewInit {
  @Input() cssClass0: string = 'block xl:hidden';
  @Input() cssClass1: string = '';
  @Input() cssClass2: string = '';
  @Input() cssClass3: string = '';
  @Input() cssClass4: string = '';

  @Output() serviceItemClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    if (typeof window === 'undefined') {
      return;
    }

    setTimeout(() => {
      [].forEach.call(this.document.querySelectorAll('.js-show-info'), (infoLink: any) => {
        DomHelperService.delay(infoLink, function () {
          DomHelperService.showContent(infoLink.querySelector('.js-info'));
        });

        infoLink.addEventListener('mouseleave', () => {
          if (this.document.querySelector('.js-info.block') !== null) {
            DomHelperService.hideContent(this.document.querySelector('.js-info.block'));
          }
        });
      });
    }, 750);

    if (window.innerWidth < 1280) {
      [].forEach.call(this.document.querySelectorAll('.js-show-info'), (infoLink: any) => {
        infoLink.addEventListener('click', (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          if (infoLink.querySelector('.js-info').classList.contains('block')) {
            DomHelperService.hideContent(infoLink.querySelector('.js-info'));
          } else {
            DomHelperService.showContent(infoLink.querySelector('.js-info'));
          }
        });
      });

      [].forEach.call(this.document.querySelectorAll('.js-mobile-nav .js-cart .w-screen'), (cartEl: Element) => {
        cartEl.classList.remove('w-screen');
        cartEl.classList.add('w-83vw');
      });

      const jsCart: Element | null = this.document.querySelector('.js-close-subnav .js-cart');
      if (jsCart) {
        jsCart.classList.remove('-right-3');
        jsCart.classList.add('right-0');
      }
    }
  }

  closeMenuByInnerComponentClick() {
    if (this.document.querySelector('.js-info.block') !== null) {
      DomHelperService.hideContent(this.document.querySelector('.js-info.block'));
      this.serviceItemClicked.emit();
    }
  }
}
